import React, { useEffect } from "react"
import { navigate, Link } from "gatsby"
import Layout from "../components/Layout"

export default function Ansprechpersonen() {
  // Seite heißt nun Vertrauenspersonen
  useEffect(() => {
    if (typeof window === `undefined`) {
      navigate("/vertrauenspersonen")
    }
  })

  return (
    <Layout title="Gau Alt-Burgund | Ansprechpersonen">
      <p style={{ margin: "2rem" }}>
        Diese Seite befindet sich nun auf{" "}
        <Link to={"/vertrauenspersonen"}>
          https://altburgund.de/vertrauenspersonen
        </Link>
      </p>
      <p style={{ margin: "2rem" }}>leite weiter...</p>
    </Layout>
  )
}
